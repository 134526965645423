import { RefundRequestComponent } from './refund-request/refund-request.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { DashboardDemoComponent } from './demo/view/dashboarddemo.component';
import { FormLayoutDemoComponent } from './demo/view/formlayoutdemo.component';
import { PanelsDemoComponent } from './demo/view/panelsdemo.component';
import { OverlaysDemoComponent } from './demo/view/overlaysdemo.component';
import { MediaDemoComponent } from './demo/view/mediademo.component';
import { MenusDemoComponent } from './demo/view/menusdemo.component';
import { MessagesDemoComponent } from './demo/view/messagesdemo.component';
import { MiscDemoComponent } from './demo/view/miscdemo.component';
import { EmptyDemoComponent } from './demo/view/emptydemo.component';
import { ChartsDemoComponent } from './demo/view/chartsdemo.component';
import { FileDemoComponent } from './demo/view/filedemo.component';
import { DocumentationComponent } from './demo/view/documentation.component';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './pages/app.login.component';
import { InputDemoComponent } from './demo/view/inputdemo.component';
import { ButtonDemoComponent } from './demo/view/buttondemo.component';
import { TableDemoComponent } from './demo/view/tabledemo.component';
import { ListDemoComponent } from './demo/view/listdemo.component';
import { TreeDemoComponent } from './demo/view/treedemo.component';
import { DisplayComponent } from './utilities/display.component';
import { ElevationComponent } from './utilities/elevation.component';
import { FlexboxComponent } from './utilities/flexbox.component';
import { GridComponent } from './utilities/grid.component';
import { IconsComponent } from './utilities/icons.component';
import { WidgetsComponent } from './utilities/widgets.component';
import { SpacingComponent } from './utilities/spacing.component';
import { TypographyComponent } from './utilities/typography.component';
import { TextComponent } from './utilities/text.component';
import { AppCrudComponent } from './pages/app.crud.component';
import { AppCalendarComponent } from './pages/app.calendar.component';
import { AppInvoiceComponent } from './pages/app.invoice.component';
import { AppHelpComponent } from './pages/app.help.component';
import { FloatLabelDemoComponent } from './demo/view/floatlabeldemo.component';
import { InvalidStateDemoComponent } from './demo/view/invalidstatedemo.component';
import { AppTimelineDemoComponent } from './pages/app.timelinedemo.component';
import { InviteVendorFormComponent } from './invite-vendor-form/invite-vendor-form.component';
import { VendorInformationComponent } from './vendor-information/vendor-information.component';
import { VendorPaymentsComponent } from './vendor-payments/vendor-payments.component';
import { VendorApprovalComponent } from './vendor-approval/vendor-approval.component';
import { LoginComponent } from './login/login.component';
import { PurchaseOrderRequestComponent } from './purchase-order-request/purchase-order-request.component';
import { PurchaseOrderApprovalComponent } from './purchase-order-approval/purchase-order-approval.component';
import { SubmitVendorInvoiceComponent } from './submit-vendor-invoice/submit-vendor-invoice.component';
import { ApproveVendorInvoiceComponent } from './approve-vendor-invoice/approve-vendor-invoice.component';
import { ExportApprovedInvoicesComponent } from './export-approved-invoices/export-approved-invoices.component';
import { PayApprovedInvoicesComponent } from './pay-approved-invoices/pay-approved-invoices.component';
import { InviteAcceptanceComponent } from './invite-acceptance/invite-acceptance.component';
import { AuthGuard } from '../helpers/auth.guard';
import { ViewVendorsComponent } from './view-vendors/view-vendors.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { RedirectComponent } from './redirect/redirect.component';
import { ManagerInvoiceApprovalComponent } from './manager-invoice-approval/manager-invoice-approval.component';
import { CorporateInvoiceApprovalComponent } from './corporate-invoice-approval/corporate-invoice-approval.component';
import { ImportSagePaymentsComponent } from './import-sage-payments/import-sage-payments.component';
import { LogoutComponent } from './logout/logout.component';
import { PurchaseOrderListComponent } from './purchase-order-list/purchase-order-list.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { DEVJABComponent } from './devjab/devjab.component';
import { VendorPendingSetupComponent } from './vendor-pending-setup/vendor-pending-setup.component';
import { ViewPaidInvoicesComponent } from './view-paid-invoices/view-paid-invoices.component';
import { VendorExpiringInsuranceComponent } from './vendor-expiring-insurance/vendor-expiring-insurance.component';
import { OpenManagerPosComponent } from './reports/open-manager-pos/open-manager-pos.component';
import { SettingsComponent } from './settings-page/settings.component';
import { ManagerDashboardComponent } from './manager-dashboard/manager-dashboard.component';
import { ExpiredDocumentsComponent } from './reports/expired-documents/expired-documents.component';
import { FilesWaitingApprovalComponent } from './files-waiting-approval/files-waiting-approval.component';

/**
 * Login and default redirection routing taken from:
 * https://stackoverflow.com/a/65963727/14560781
 */

const routes: Routes = [
    {
        path: 'login',
        children: [
            {
                path: 'password_reset',
                children: [
                    { path: 'reset', component: ChangePasswordComponent },
                    { path: '', component: PasswordResetComponent, pathMatch: 'full' },
                ],
            },
            { path: '', component: LoginComponent, pathMatch: 'full' },
        ],
    },
    { path: 'logout', component: LogoutComponent },
    {
        path: '',
        component: AppMainComponent,
        canActivateChild: [AuthGuard],
        children: [
            { path: '', pathMatch: 'full', component: RedirectComponent },
            { path: 'dev/jab', component: DEVJABComponent },
            { path: 'vendor/info', component: VendorInformationComponent, canActivate: [AuthGuard] },
            { path: 'vendor/invite', component: InviteVendorFormComponent, canActivate: [AuthGuard] },
            {
                path: 'vendor/invite/accept',
                component: InviteAcceptanceComponent,
                canActivate: [AuthGuard],
            },
            { path: 'vendor/expiring', component: VendorExpiringInsuranceComponent, canActivate: [AuthGuard] },
            { path: 'vendor/payments', component: VendorPaymentsComponent, canActivate: [AuthGuard] },
            { path: 'vendor/invoice', component: SubmitVendorInvoiceComponent, canActivate: [AuthGuard] },
            { path: 'vendor/all', component: ViewVendorsComponent, canActivate: [AuthGuard] },
            {
                path: 'accounting/invoices',
                component: ViewPaidInvoicesComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'accounting/vendor/approval',
                component: VendorApprovalComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'accounting/vendor/pending-setup',
                component: VendorPendingSetupComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'accounting/payments/import',
                component: ImportSagePaymentsComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'accounting/payable/approval',
                component: ApproveVendorInvoiceComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'accounting/files/approval',
                component: FilesWaitingApprovalComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'accounting/payable/export',
                component: ExportApprovedInvoicesComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'purchasing/request',
                component: PurchaseOrderRequestComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'purchasing/approval',
                component: PurchaseOrderApprovalComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'purchasing/list',
                component: PurchaseOrderListComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'accounts-payable/mark-paid',
                component: PayApprovedInvoicesComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'users',
                component: UserManagementComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'manager/invoice/approval',
                component: ManagerInvoiceApprovalComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'manager/dashboard',
                component: ManagerDashboardComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'corporate/invoice/approval',
                component: CorporateInvoiceApprovalComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'reports/manager-open-purchase-orders',
                component: OpenManagerPosComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'settings',
                component: SettingsComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'expired-documents',
                component: ExpiredDocumentsComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'refund',
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
                children: [
                    {
                        path: 'request',
                        component: RefundRequestComponent,
                        canActivate: [AuthGuard],
                    },
                ],
            },
            {
                path: '**',
                pathMatch: 'full',
                redirectTo: ''
            }
        ],
    },
    { path: '**', redirectTo: 'login' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
