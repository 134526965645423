import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Vendor } from '../app/api/models/vendor';
import { InvitationPartial } from '../app/api/models/invitation-partial';
import { LoginModel } from '../app/models/login.model';
import { environment } from '../environments/environment';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { Invoice } from '../app/api/models/invoice';
import { catchError, finalize, first, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { PurchaseOrder } from 'src/app/api/models/purchase-order';
import { User } from 'src/app/api/models/user';
import { FilePartial } from 'src/app/api/models/file-partial';
import { DateTime } from 'luxon';

import Hashids from 'hashids';
import { IdCheck } from 'src/app/change-password/IdCheck';
import { GLCodeGenerator } from '../shared/gl-code-generation';
import { Settings } from 'src/app/api/models/settings';
import { InvoiceLocationAndGLDistribution } from '../app/api/models/mpi-custom-models';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { LocationsPartial } from '../app/api/models/locations-partial';
import { File, ViewInvoice, ViewPurchaseOrder } from 'src/app/api/models';
import { ImportMatch } from 'src/app/import-sage-payments/importMatch';

//TODO MPIWEB-667 Move into Loopback and share to Angular as common model
export interface LocationSetting {
    // The internal locationID
    id: number;
    // The name of the location
    location: string;
    // The GL Code associated with this location
    glCode: string;
}

// Create a new interface that contains all the vendor information and holds the files
export interface VendorWithFiles extends Vendor {
    files?: {
        w9?: File[] | FilePartial[];
        insurance?: File[] | FilePartial[];
    };
}

export interface VendorWithUser extends Vendor {
    accountVerified?: boolean;
}

export interface PurchaseOrdersAndVendors {
    vendors: Vendor[];
    pos: PurchaseOrder[];
}

@Injectable({
    providedIn: 'root',
})
export class MPIAppService {
    public editDataDetails: any = [];
    public subject = new Subject<any>();
    private messageSource = new BehaviorSubject(this.editDataDetails);
    currentMessage = this.messageSource.asObservable();

    public glCodeGenerator: GLCodeGenerator;

    public UPLOAD_ALLOWED_TYPES = '.png,.jpg,.pdf';
    public UPLOAD_MAX_BYTE_SIZE = 20000000;

    public HASHIDS_SALT = 'Modern Parking Inc.';
    public HASHIDS_PAD = 8;
    public HASHIDS_DICT = 'ACDEFGHJKLMNPQRTUVWXY34679';
    public idhasher = new Hashids(this.HASHIDS_SALT, this.HASHIDS_PAD, this.HASHIDS_DICT);

    // What are the current roles that the user has access to?
    public currentRoles: string[];

    // What is the current role actively being performed?
    public activeRole: string;

    constructor(private http: HttpClient) {
        this.currentRoles = [];

        // For development set default role
        // this.currentRoles = ['manager'];
    }

    // Function to initialize the GL Code Generator
    // Called on app load
    initGLCodeList() {
        console.log('GL Code Generator: ', this.glCodeGenerator);
        if (this.glCodeGenerator === undefined) {
            return this.getSetting('locations').pipe(
                tap({
                    next: (data) => {
                        this.glCodeGenerator = new GLCodeGenerator();
                        this.glCodeGenerator.glCodeList = JSON.parse(data.value);
                    },
                })
            );
        } else {
            return of(null);
        }
    }

    idToHash(id: number): string {
        return this.idhasher.encode(id);
    }

    /**
     * Take the list of location / GL Code / amount and normalize it into a datastructure
     */
    normalizeLocationDistribution(glformLocationArray: UntypedFormArray, locations: LocationsPartial[]): Object {
        const locationPayload = {};
        const formArray = glformLocationArray;

        let counter = 0;

        for (const control of formArray.controls) {
            const fields: InvoiceLocationAndGLDistribution = {
                glCode: '',
                comment: '',
                locationID: 0,
                locationName: '',
                amount: null,
            };

            const location = control.get('location').value;

            // Look through the locations we know about and resolve the location name back to an locationID
            for (const loc of locations) {
                if (loc.name === location) {
                    fields.locationID = loc.locationID;

                    // We found our location so end the search
                    break;
                }
            }

            fields.locationName = String(location);
            fields.amount = control.get('amount')?.value ? control.get('amount').value : 0;
            fields.glCode = control.get('glCode')?.value ? control.get('glCode').value : '';
            fields.comment = control.get('comment')?.value ? control.get('comment').value : '';

            // The location distribution is keyed based on an arbitrary counter - not the location name or ID
            // so that the same location can appear more than once.
            locationPayload[counter] = fields;

            counter++;
        }

        return locationPayload;
    }

    // Given a string to string that has currency format it return it as a number string without currency.
    stripCurrency(currency: string) {
        currency = currency.replace(/\,/gi, '');
        currency = currency.replace(/\$/gi, '');
        return currency;
    }

    changeMessage(message: string) {
        this.messageSource.next(message);
    }

    primaryRole() {
        return 'manager';
    }

    allRoles() {
        return ['manager', 'vendor', 'corporate-office', 'accounting', 'accounts-payable', 'admin'];
    }

    userRoles() {
        return this.currentRoles;
    }

    /**
     *
     * Given a role name see if the current session can perform that role.
     *
     * @param roleName
     */
    canPerformRole(roleName): boolean {
        // The user can perform the role if either their active role or allowed roles permit it.
        // console.log(`Checking if ${roleName} is in:`, this.currentRoles);
        if (this.activeRole === roleName || this.currentRoles.includes(roleName)) {
            return true;
        } else {
            return false;
        }
    }

    setRole(role) {
        this.activeRole = role;
    }

    /**
     * Given a role name reload the main menu for that role. If no role name supplied then
     * the default primary role for the account is used.
     * @param forRole
     */
    getMenuModel(forRole = '') {
        let model: any[] = [];
        model.push({ label: 'Logout', icon: 'pi pi-fw pi-sign-out', routerLink: ['/logout'] });

        let role = '';

        if (forRole === '') {
            // No role was provided so use whatever the default role is
            role = this.activeRole;
        } else {
            // A role was provided so display the menu for that role
            role = forRole;
        }

        switch (role) {
            case 'accounting':
                model = model.concat([
                    {
                        label: 'View Vendors',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/vendor/all'],
                        visible: true,
                    },
                    {
                        label: 'Vendor Approval',
                        icon: 'pi pi-fw pi-home',
                        routerLink: ['/accounting/vendor/approval'],
                    },
                    {
                        label: 'Vendor Pending Setup',
                        icon: 'pi pi-users',
                        routerLink: ['/accounting/vendor/pending-setup'],
                        visible: true,
                    },
                    {
                        label: 'Invoice Approval',
                        icon: 'pi pi-fw pi-check',
                        routerLink: ['/accounting/payable/approval'],
                    },
                    {
                        label: 'Files Waiting For Approval',
                        icon: 'pi pi-fw pi-check-square',
                        routerLink: ['/accounting/files/approval'],
                    },
                    {
                        label: 'Export Approved',
                        icon: 'pi pi-fw pi-home',
                        routerLink: ['/accounting/payable/export'],
                    },
                    {
                        label: 'Import Payments',
                        icon: 'pi pi-fw pi-file-excel',
                        routerLink: ['/accounting/payments/import'],
                    },
                    {
                        label: 'Invoices Paid',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/accounting/invoices'],
                    },
                    {
                        label: 'Reports > Open Purchase Orders',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/reports/manager-open-purchase-orders'],
                    },
                ]);
                break;
            case 'accounts-payable':
                model = model.concat([
                    {
                        label: 'View Vendors',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/vendor/all'],
                        visible: true,
                    },
                    {
                        label: 'Invoice Approval',
                        icon: 'pi pi-fw pi-check',
                        routerLink: ['/accounting/payable/approval'],
                    },
                    {
                        label: 'Export Approved',
                        icon: 'pi pi-fw pi-home',
                        routerLink: ['/accounting/payable/export'],
                    },
                    {
                        label: 'Import Payments',
                        icon: 'pi pi-fw pi-file-excel',
                        routerLink: ['/accounting/payments/import'],
                    },
                    {
                        label: 'Invoices Paid',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/accounting/invoices'],
                    },
                    {
                        label: 'Reports > Open Purchase Orders',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/reports/manager-open-purchase-orders'],
                    },
                ]);
                break;
            case 'admin':
                model.push({
                    label: 'Users',
                    icon: 'pi pi-users',
                    routerLink: ['/users'],
                    visible: true,
                });
                model.push({
                    label: 'Vendors Expiring',
                    icon: 'pi pi-list',
                    routerLink: ['/vendor/expiring'],
                    visible: true,
                });
                model.push({
                    label: 'Expired Documents',
                    icon: 'pi pi-file-excel',
                    routerLink: ['/expired-documents'],
                    visible: true,
                });
                model.push({
                    label: 'Settings',
                    icon: 'pi pi-cog',
                    routerLink: ['/settings'],
                    visible: true,
                });
                if (environment.devModeActive === true) {
                    model.push({
                        label: 'DEV Jab',
                        icon: 'pi pi-users',
                        routerLink: ['/dev/jab'],
                        visible: true,
                    });
                }

                break;
            case 'corporate-office':
                model = model.concat([
                    {
                        label: 'Purchase Order Approval',
                        icon: 'pi pi-fw pi-home',
                        routerLink: ['/purchasing/approval'],
                    },
                    {
                        label: 'View Purchase Orders',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/purchasing/list'],
                    },
                    {
                        label: 'Invoice Approval',
                        icon: 'pi pi-fw pi-check',
                        routerLink: ['/corporate/invoice/approval'],
                    },
                    {
                        label: 'Invoices Paid',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/accounting/invoices'],
                    },
                    {
                        label: 'Reports > Open Purchase Orders',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/reports/manager-open-purchase-orders'],
                    },
                ]);
                break;
            case 'manager':
                model = model.concat([
                    {
                        label: 'Dashboard',
                        icon: 'pi pi-fw pi-home',
                        routerLink: ['/manager/dashboard'],
                    },
                    {
                        label: 'Vendors',
                        icon: 'pi pi-fw pi-users',
                        routerLink: ['/uikit'],
                        items: [
                            {
                                label: 'Invite Vendor',
                                icon: 'pi pi-fw pi-user-plus',
                                routerLink: ['/vendor/invite'],
                                visible: true,
                            },
                            {
                                label: 'View Vendors',
                                icon: 'pi pi-fw pi-list',
                                routerLink: ['/vendor/all'],
                                visible: true,
                            },
                        ],
                    },
                    {
                        label: 'Request Purchase Order',
                        icon: 'pi pi-fw pi-ticket',
                        routerLink: ['/purchasing/request'],
                    },
                    {
                        label: 'View Purchase Orders',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/purchasing/list'],
                    },
                    {
                        label: 'Invoice Approval',
                        icon: 'pi pi-fw pi-check',
                        routerLink: ['/manager/invoice/approval'],
                    },
                    {
                        label: 'Invoices Paid',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/accounting/invoices'],
                    },
                    {
                        label: 'Submit Invoice',
                        icon: 'pi pi-fw pi-money-bill',
                        routerLink: ['/vendor/invoice'],
                    },
                    {
                        label: 'Refund Request',
                        icon: 'pi pi-fw pi-dollar',
                        routerLink: ['/refund/request'],
                    },
                ]);
                break;
            case 'vendor':
                model = model.concat([
                    {
                        label: 'My Account Information',
                        icon: 'pi pi-fw pi-home',
                        routerLink: ['/vendor/info'],
                    },
                    {
                        label: 'Payment Center',
                        icon: 'pi pi-fw pi-home',
                        routerLink: ['/vendor/payments'],
                    },
                    {
                        label: 'Submit Invoice',
                        icon: 'pi pi-fw pi-home',
                        routerLink: ['/vendor/invoice'],
                    },
                ]);
                break;
            case '':
                break;
        }

        return model;
    }

    listLocations(filter) {
        return this.http
            .get<any>('assets/mpi-dev/locations.json')
            .toPromise()
            .then((res) => res.locations);
    }

    listVendors(filter) {
        return this.http.get<any>('assets/mpi-dev/vendors.json');
    }

    inviteVendor(vendor: Vendor) {
        const vendorString = JSON.stringify(vendor);

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        return this.http.post(this.getURL('vendor'), vendorString, httpOptions);
    }

    inviteVendorAccept(id: string, invite: InvitationPartial) {
        const vendorString = JSON.stringify(invite);

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        const url = this.getURL('invite') + '/' + id;
        return this.http.patch(url, vendorString, httpOptions);
    }

    updateVendor(id: string, payload: Vendor | Object): Observable<Object> {
        const payloadString = JSON.stringify(payload);

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        const url = this.getURL('vendor') + '/' + id;
        return this.http.patch(url, payloadString, httpOptions);
    }

    matchInvoice(paidKey: string, filename: string, checkDate: string): Observable<ImportMatch> {
        const url: string = this.getURL('invoices') + '/match';
        const requestObject = { paidKey: paidKey, filename: filename, checkDate: checkDate };

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };
        return this.http.post<ImportMatch>(url, requestObject, httpOptions)
    }

    updateInvoice(id: string | number, payload: Object) {
        const payloadString = JSON.stringify(payload);

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        const url = this.getURL('invoices') + '/' + id;
        return this.http.patch(url, payloadString, httpOptions);
    }

    updatePurchaseOrder(id: string, payload: Object): Observable<Object> {
        const payloadString = JSON.stringify(payload);

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        const url = this.getURL('purchase-order') + '/' + id;
        return this.http.patch(url, payloadString, httpOptions);
    }

    /**
     * Resubmit a PO and have it re-send notifications.
     *
     * Unlike patch which just updates fields this will trigger notifications.
     *
     * @param id
     * @param payload
     */
    resubmitPurchaseOrder(id: string, payload: Object): Observable<Object> {
        const payloadString = JSON.stringify(payload);

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        const url = this.getURL('purchase-order') + '/' + id + '/resubmit';
        return this.http.patch(url, payloadString, httpOptions);
    }

    getVendor(id: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        const url = this.getURL('vendor') + '/' + id;
        return this.http.get(url, httpOptions);
    }

    getInvoiceEvents(id: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        const url = this.getURL('invoices') + '/' + id + '/events';
        return this.http.get(url, httpOptions);
    }

    getFiles(docType?: string, docTypeID = ''): Observable<FilePartial[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        let url = '';
        if (docType && docType !== '') {
            if (docTypeID !== '') {
                url = this.getURL('filedownloads') + '?type=' + docType + '&typeid=' + docTypeID;
            } else {
                url = this.getURL('filedownloads') + '?type=' + docType;
            }
        } else {
            url = this.getURL('filedownloads');
        }

        return this.http
            .get<FilePartial[]>(url, httpOptions)
            .pipe(catchError(this.handleError<FilePartial[]>('getFiles', [])));
    }

    getVendorSpecificFiles(vendorID: string, docType: string): Observable<FilePartial[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        const url = this.getURL('vendor') + '/' + vendorID + '/files/' + docType;

        return this.http
            .get<FilePartial[]>(url, httpOptions)
            .pipe(catchError(this.handleError<FilePartial[]>('getVendorSpecificFiles', [])));
    }

    getFileMetaData(fileID: string): Observable<FilePartial> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        const url = this.getURL('file') + '/' + fileID;

        return this.http.get(url, httpOptions);
    }

    setFileMetaData(
        fileID: string,
        fieldName: string,
        fieldValue: string | number,
        denialNote?: string
    ): Observable<Object> {
        let payload: any;
        if (fieldValue === 'denied') {
            payload = { [fieldName]: fieldValue, ['note']: denialNote };
        } else if (fieldValue === 'approved') {
            payload = { [fieldName]: fieldValue, ['note']: '' };
        } else {
            payload = { [fieldName]: fieldValue };
        }
        const payloadStr = JSON.stringify(payload);

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        const url = this.getURL('file') + '/' + fileID;

        return this.http.patch(url, payloadStr, httpOptions);
    }

    getAllFiles(filter: string): Observable<File[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        const url = this.getURL('file') + '?filter=' + filter;

        return this.http.get<File[]>(url, httpOptions);
    }

    deleteFile(id: number) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        const url = this.getURL('file') + `/${id}`;

        return this.http.delete(url, httpOptions);
    }

    getInvite(id: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        const url = this.getURL('invite') + '/' + id;
        return this.http.get(url, httpOptions);
    }

    getInvoicesCount(filter: string | null): Observable<number> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };
        let url = this.getURL('view-invoices') + '/count';
        if (filter !== null) {
            url += '?where=' + filter;
        }

        return this.http.get<any>(url, httpOptions).pipe(map((res) => res.count as number));
    }

    /* GET array of invoices. NOTE: In future, should update API to return based on vendorID */
    getInvoices(filter?: string, paidKey?: string): Observable<Invoice[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        let url = this.getURL('invoices');

        if (filter) {
            url += '?filter=' + filter;
        } else if (paidKey) {
            url += '?paidkey=' + paidKey;
        }

        return this.http
            .get<Invoice[]>(url, httpOptions)
            .pipe(catchError(this.handleError<Invoice[]>('getInvoices', [])));
    }

    getInvoice(id: string): Observable<Invoice> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        const url = this.getURL('invoices') + '/' + id;

        return this.http.get<Invoice>(url, httpOptions);
    }

    getDisplayInvoices(filter?: string, paidKey?: string): Observable<ViewInvoice[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        let url = this.getURL('view-invoices');

        if (filter) {
            url += '?filter=' + filter;
        } else if (paidKey) {
            url += '?paidkey=' + paidKey;
        }

        return this.http
            .get<ViewInvoice[]>(url, httpOptions)
            .pipe(catchError(this.handleError<ViewInvoice[]>('getInvoices', [])));
    }

    getPCID(filter?: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        let url = this.getURL('invoices');

        if (filter) {
            url += '?filter=' + filter;
        }

        return this.http.get<Invoice[]>(url, httpOptions).pipe(catchError(this.handleError<Invoice[]>('getPCID', [])));
    }

    // getAccountingInvoices(filter?: string, paidKey?: string): Observable<Invoice[]> {
    //     const httpOptions = {
    //         headers: new HttpHeaders({
    //             'Content-Type': 'application/json',
    //         }),
    //     };

    //     let url = this.getURL('invoices');

    //     if (filter) {
    //         url += '?filter=' + filter;
    //     } else if (paidKey) {
    //         url += '?paidkey=' + paidKey;
    //     }

    //     return this.http
    //         .get<Invoice[]>(url, httpOptions)
    //         .pipe(
    //             switchMap((invoices: Invoice[]) => {
    //                 return forkJoin(
    //                     invoices.map((invoice: Invoice) => {
    //                         return forkJoin({
    //                             manager: this.http
    //                                 .get<User>(this.getURL('getUser') + invoice.managerApprovedUserID, httpOptions)
    //                                 .pipe(catchError(this.handleError<User>('getUser'))),
    //                             corporateOffice: this.http
    //                                 .get<User>(
    //                                     this.getURL('getUser') + invoice.corporateOfficeApprovedUserID,
    //                                     httpOptions
    //                                 )
    //                                 .pipe(catchError(this.handleError<User>('getUser'))),
    //                         }).pipe(
    //                             map((users: any) => {
    //                                 invoice.managerApprovedFirstName =
    //                                     users.manager === undefined ? null : users.manager.firstName;
    //                                 invoice.managerApprovedLastName =
    //                                     users.manager === undefined ? null : users.manager.lastName;

    //                                 invoice.corporateOfficeApprovedFirstName =
    //                                     users.corporateOffice === undefined ? null : users.corporateOffice.firstName;
    //                                 invoice.corporateOfficeApprovedLastName =
    //                                     users.corporateOffice === undefined ? null : users.corporateOffice.lastName;

    //                                 return invoice;
    //                             })
    //                         );
    //                     })
    //                 );
    //             })
    //         )
    //         .pipe(catchError(this.handleError<Invoice[]>('getAccountingInvoices', [])));
    // }

    downloadFile(fileID: string): any {
        const url = this.getURL('filedownloads') + '/' + fileID;
        return this.http.get(url, { responseType: 'blob' });
    }

    /**
     * Given a document fileID download the file using the current session's auth token and display the file in a new
     * window. Without this function the user will receive a 401 Authorization header not found error.
     *
     * @param fileID The document file ID to display
     */
    showFile(fileID: string) {
        this.downloadFile(String(fileID)).subscribe((response) => {
            // console.log(response);
            // let blob:any = new Blob([response], { type: 'application/pdf; charset=utf-8' });
            // let blob:any = new Blob([response], { type: 'application/binary; charset=utf-8' });
            let blob: any = new Blob([response], { type: response.type });

            const url = window.URL.createObjectURL(blob);
            // console.log(url);
            window.open(url);
        });
    }

    getLocations(filter?: string): Observable<any[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        let url = this.getURL('location');

        if (filter) {
            url += '?filter=' + filter;
        }

        return this.http.get<any[]>(url, httpOptions).pipe(catchError(this.handleError<any[]>('getLocations', [])));
    }

    getVendors(id?: string, filter?: string): Observable<Vendor | Vendor[]> {
        console.log(`Getting vendors`);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        let url = this.getURL('vendor');
        if (id) {
            url += '/' + id;
        }

        if (filter) {
            url += '?filter=' + filter;
        }

        return this.http.get<Vendor[]>(url, httpOptions).pipe(catchError(this.handleError<Vendor[]>('getVendors', [])));
    }

    getVendorsWithExpiringDocuments(): Observable<any[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        const url = this.getURL('vendor') + '/expiring';

        return this.http
            .get<Vendor[]>(url, httpOptions)
            .pipe(catchError(this.handleError<Vendor[]>('getVendorsWithExpiringDocuments', [])));
    }

    /**
     * Get a vendor ID for this user that is a reimbursement Vendor or create one if needed.
     */
    getOrCreateReimbursementVendor(): Observable<Vendor> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        const url = environment.apiBaseURL + '/user/reimbursement-id';

        // This catches the error in the service and returns an empty object which makes it
        // difficult to catch and display the error in the UI
        // return this.http.get<Vendor>(url, httpOptions).pipe(
        //     catchError(this.handleError<Vendor>('getVendors', {} as Vendor))
        // );

        return this.http.get<Vendor>(url, httpOptions);
    }

    /**
     * Similar to getVendors but includes information about the files associated with the vendor.
     * @param id
     * @param filter
     */
    getVendorsWithFiles(id?: string, filter?: string): Observable<VendorWithFiles[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        let url = this.getURL('vendor');
        if (id) {
            url += '/' + id;
        }

        if (filter) {
            url += '?filter=' + filter;
        }

        return this.http.get<Vendor[]>(url, httpOptions).pipe(
            // Take the incoming vendor array and feed it into mergeMap
            mergeMap((vendors: VendorWithFiles[]) => {
                // Create an array that will contain our new list of observables that will
                // have the Vendor object extended to VendorWithFiles.
                const vendorsWithFiles: Observable<VendorWithFiles>[] = [];

                // Loop over all of the vendors we received.
                vendors.forEach((vendor: Vendor, index) => {
                    // Call our .getVendorWithFiles to turn the Vendor into VendorWithFiles
                    vendorsWithFiles.push(this.getVendorWithFiles(vendor));
                });

                // Join our existing array of observables into a single observable.
                return forkJoin(vendorsWithFiles);
            })
        );
    }

    /**
     * Given a vendor object get the files associated with it and return it as a new object observable
     * @param vendor
     */
    getVendorWithFiles(vendor: Vendor): Observable<VendorWithFiles> {
        // We need the vendor ID from the supplied vendor.
        const vendorID = String(vendor.vendorID);

        // Return a new object that is a single observable.
        return forkJoin([
            // Get the existing depedencies
            this.getVendorSpecificFiles(vendorID, 'w9'),
            this.getVendorSpecificFiles(vendorID, 'insurance'),
        ]).pipe(
            // Work on the dependencies
            map((data: any[]) => {
                // data contains an array of items in the same order they were referenced in forkJoin.

                // Build a new object and return it with our resolved dependency data.
                const vendorWithFiles = vendor as VendorWithFiles;
                vendorWithFiles.files = {
                    w9: data[0],
                    insurance: data[1],
                };

                // Return our new observable object
                return vendorWithFiles;
            })
        );
    }

    /**
     * Get a list of all purchase order and vendor resources the current user has access to
     */
    getPurchaseOrdersAndVendors(poFilter?: string, filterByLocation = false): Observable<PurchaseOrdersAndVendors> {
        return forkJoin([this.getPurchaseOrders(poFilter, filterByLocation), this.getVendors()]).pipe(
            map((data: any[]) => {
                // The order of the data array depends on the order of the calls in forkJoin
                const response = {
                    vendors: data[1],
                    pos: data[0],
                };

                return response;
            })
        );
    }

    getPurchaseOrderCount(filter: string): Observable<number> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };
        let url = this.getURL('view-purchase-orders') + '/count';
        url += '?where=' + filter;

        return this.http.get<any>(url, httpOptions).pipe(map((res) => res.count as number));
    }

    getDisplayPurchaseOrders(filter?: string): Observable<ViewPurchaseOrder[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        let url = this.getURL('view-purchase-orders');

        if (filter) {
            url += '?filter=' + filter;
        }

        return this.http
            .get<ViewPurchaseOrder[]>(url, httpOptions)
            .pipe(catchError(this.handleError<ViewPurchaseOrder[]>('getPurchaseOrders', [])));
    }

    getPurchaseOrders(filter?: string, filterByLocation = false): Observable<PurchaseOrder[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        let url = this.getURL('purchase-order');

        if (filterByLocation) {
            url += '?filterByLocation=1';
            if (filter) {
                url += '&filter=' + filter;
            }
        } else if (filter) {
            url += '?filter=' + filter;
        }

        return this.http
            .get<PurchaseOrder[]>(url, httpOptions)
            .pipe(catchError(this.handleError<PurchaseOrder[]>('getPurchaseOrders', [])));
    }

    getPurchaseOrder(purchaseOrderID: string): Observable<PurchaseOrder> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        const url = this.getURL('purchase-order') + '/' + purchaseOrderID;

        return this.http.get<PurchaseOrder>(url, httpOptions);
    }

    getUsers(filter?: string): Observable<User[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        let url = this.getURL('users');
        if (filter) {
            url += '?filter=' + filter;
        }

        return this.http.get<User[]>(url, httpOptions).pipe(catchError(this.handleError<User[]>('getUsers', [])));
    }

    getUser(userID: string): Observable<User> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        return this.http
            .get<User>(this.getURL('users') + '/' + userID, httpOptions)
            .pipe(catchError(this.handleError<User>('getUser')));
    }

    /*
    Given a list of invoice IDs mark them as exported and return the download.
     */
    exportInvoices(invoiceIDs: string[]): Observable<any> {
        const payload = {
            list: invoiceIDs,
        };

        const payloadStr = JSON.stringify(payload);

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            responseType: 'blob' as const,
        };

        return this.http.post(this.getURL('invoices-export'), payloadStr, httpOptions);
    }

    newUser(newuser: User): Observable<Object> {
        const userString = JSON.stringify(newuser);

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        return this.http.post(this.getURL('newuser'), userString, httpOptions);
    }

    printAllDocuments(invoiceID: number): Observable<any> {
        const httpOptions: any = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            responseType: 'blob',
        };
        const url = this.getURL('print-pdf') + '/' + invoiceID + '/docs';

        return this.http.get<any | null>(url, httpOptions).pipe(
            tap({
                next: (returnObj) => {
                    console.log('printAllDocs: ', returnObj);
                    if (returnObj === null) {
                        throw new HttpErrorResponse({
                            status: 501,
                            statusText: 'There was an error with merging the PDFs.',
                        });
                    }
                    return of(returnObj);
                },
            })
        );
    }

    /**
     * Update a user with a new value
     * Meant for user management page to either update the user password or locations list, one at a time or
     * PATCH field values (multiple accepted).
     *
     * @param updateType string for update key in payload, either 'password' or 'locationsNameList'. If empty ''
     * then the values are sent directly to the server as a PATCH operation.
     * @param updateValue value to be updated to
     * @param userID user id of user to be updated
     * @returns
     */
    updateUser(updateType: string, updateValue: any, userID: string): Observable<Object> {
        let payload = {};

        if (updateType === '') {
            // Just patch the values, no special wrapper needed
            payload = updateValue;
        } else {
            // We have a special wrapper for the action so wrap the values in the payload.
            payload[updateType] = updateValue;
        }

        const payloadStr = JSON.stringify(payload);

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        return this.http.patch(this.getURL('users') + '/' + userID, payloadStr, httpOptions);
    }

    getUserID(resetID: string): Observable<IdCheck> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        return this.http.get<IdCheck>(this.getURL('getUserID') + resetID, httpOptions);
    }

    resetUserPassword(payload: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        const payloadString = JSON.stringify(payload);

        const url = this.getURL('completePasswordReset');
        return this.http.patch(url, payloadString, httpOptions);
    }

    login(payload: LoginModel) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        const payloadString = JSON.stringify(payload);

        const url = this.getURL('login');
        return this.http.post(url, payloadString, httpOptions);
    }

    switchVendor(payload: LoginModel) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        const payloadString = JSON.stringify(payload);

        const url = this.getURL('switch-vendor');
        return this.http.post(url, payloadString, httpOptions);
    }

    requestPasswordReset(username: string): Observable<Object> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        const payload = JSON.stringify({ subject: username });

        const url = this.getURL('passwordReset');
        return this.http.post(url, payload, httpOptions);
    }

    getSetting(setting: string): Observable<Settings> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        const url = this.getURL('settings') + '/' + setting;

        return this.http.get<Settings>(url, httpOptions);
    }

    postSetting(setting: Settings): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        const payload = JSON.stringify(setting);

        const url = this.getURL('settings');
        return this.http.post(url, payload, httpOptions);
    }

    patchSetting(setting: Settings): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        const payload = JSON.stringify(setting);

        const url = this.getURL('settings') + `/${setting.name}`;
        return this.http.patch(url, payload, httpOptions);
    }

    /*
    Get a URL from the API server. This does not handle front-end URLs from within the app
     */
    getURL(url) {
        let segment = '';

        switch (url) {
            case 'vendor': {
                segment = '/vendor';
                break;
            }
            case 'filedownloads': {
                segment = '/filedownloads';
                break;
            }
            case 'file': {
                segment = '/files';
                break;
            }
            case 'fileuploads': {
                segment = '/fileuploads';
                break;
            }
            case 'invite': {
                segment = '/invitation';
                break;
            }
            case 'login': {
                segment = '/users/login';
                break;
            }
            case 'invoices': {
                segment = '/invoices';
                break;
            }
            case 'invoices-export': {
                segment = '/invoices/export-approved';
                break;
            }
            case 'location': {
                segment = '/location';
                break;
            }
            case 'purchase-order': {
                segment = '/purchase-order';
                break;
            }
            case 'switch-vendor': {
                segment = '/users/switch-vendor';
                break;
            }
            case 'users': {
                segment = '/users';
                break;
            }
            case 'newuser': {
                segment = '/users/sign-up';
                break;
            }
            case 'passwordReset': {
                segment = '/users/reset-password';
                break;
            }
            case 'getUserID': {
                segment = '/users/resetID/';
                break;
            }
            case 'completePasswordReset': {
                segment = '/users/changePassword';
                break;
            }
            case 'print-pdf': {
                segment = '/invoices';
                break;
            }
            case 'settings': {
                segment = '/settings';
                break;
            }
            case 'view-invoices': {
                segment = '/view-invoices';
                break;
            }
            case 'view-purchase-orders': {
                segment = '/view-purchase-orders';
                break;
            }
        }

        return environment.apiBaseURL + segment;
    }

    /*
    Given a HTTPError see if it's a generic or API specific error and get a message.
     */
    getAPIErrorForDisplay(error: any): string {
        if (error?.error?.error?.message) {
            // This appears to be a custom HTTP error show show the message from the JSON body
            return error.error.error.message;
        }

        if (error?.message) {
            // This is just a general HTTP error so show the message
            return error.message;
        }

        // Unknown error type so return whatever toString will show
        return error.toString();
    }

    /**
     * Handle HTTP operation that failed
     * Let app continue
     *
     * From live example of Angular Tour of Heroes: https://angular.io/generated/live-examples/toh-pt6/stackblitz.html
     * @param operation - name of operation which failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);

            return of(result as T);
        };
    }

    /*
    Given a date object return a human readible string in the application's default format
     */
    convertDateToDisplayDate(dateObj: Date | DateTime): string {
        const DEFAULT_DATE_FORMAT = 'M/d/y';

        if (dateObj instanceof DateTime) {
            return dateObj.toFormat(DEFAULT_DATE_FORMAT);
        }

        if (dateObj instanceof Date) {
            const dispDate = DateTime.fromJSDate(dateObj);
            return dispDate.toFormat(DEFAULT_DATE_FORMAT);
        }

        throw new Error('Date not implemented');
    }

    public findInvalidControls(formToSearch: UntypedFormGroup | UntypedFormArray): string[] {
        const invalidControls: string[] = [];

        // Loop through all controls in the form group or form array.
        // Recursive to account for subgroups or lists of form groups.
        let recursiveFunc = (form: UntypedFormGroup | UntypedFormArray) => {
            Object.keys(form.controls).forEach((field) => {
                const control = form.get(field);
                if (control.invalid) invalidControls.push(field);
                if (control instanceof UntypedFormGroup) {
                    recursiveFunc(control);
                } else if (control instanceof UntypedFormArray) {
                    recursiveFunc(control);
                }
            });
        };

        recursiveFunc(formToSearch);

        return invalidControls;
    }
}
